/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useDebounce } from "usehooks-ts";
import BoldSubstring from "./BoldSubstring";
import { Combobox, Input } from "@src/common/components";
import { useGoogleAutocompleteApi } from "@src/common/lib/external/googlemaps/useAutocompleteApi";
import { useGooglePlaceDetails } from "@src/common/lib/external/googlemaps/useGooglePlaceDetails";
import { XMarkIcon } from "@heroicons/react/24/solid";

type AddressInputProps = {
  handleAddressChange: (
    address: google.maps.places.AutocompletePrediction | undefined
  ) => void;
  placeholder?: string;
  autoComplete?: string;
  control?: any;
  required?: boolean;
  value?: string;
  positive?: boolean;
  addressTypesForPlacesAutocomplete?: string[];
  size?: "mini" | "default" | "compact" | "large" | undefined;
  cleanOnBlur?: boolean;
  name?: string;
  disabled?: boolean;
};

export default function AddressInput({
  handleAddressChange,
  placeholder = "Search for an address",
  autoComplete = "off",
  control,
  required = false,
  value,
  positive = false,
  addressTypesForPlacesAutocomplete,
  size,
  cleanOnBlur = true,
  name,
  disabled = false,
}: AddressInputProps) {
  const [inputValue, setInputValue] = React.useState("");
  const debouncedQueryAddressValue = useDebounce<string>(inputValue, 500);
  const inputRef = useRef<HTMLElement>(null);
  const { place, fetchPlaceDetails } = useGooglePlaceDetails();
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    if (place) {
      setInputValue(place.formatted_address || "");
      setIsEditable(false);
    }
  }, [place]);

  const {
    predictions,
  }: {
    predictions: google.maps.places.AutocompletePrediction[];
  } = useGoogleAutocompleteApi(
    debouncedQueryAddressValue,
    addressTypesForPlacesAutocomplete
  );

  useEffect(() => {
    if (!value) {
      setInputValue("");
      setIsEditable(true);
    } else {
      if (value !== inputValue) {
        setInputValue(value);
        setIsEditable(false);
      }
    }
  }, [value]);

  function mapOptionToString(
    option: google.maps.places.AutocompletePrediction
  ): string {
    return option.description;
  }

  function handleChange(
    nextValue: string,
    option?: google.maps.places.AutocompletePrediction | null
  ) {
    setInputValue(nextValue);
    if (option) {
      handleAddressChange(option);
      fetchPlaceDetails(option.place_id);
    } else {
      handleAddressChange(undefined);
    }
  }

  const renderOptionNode = ({
    option,
  }: {
    option?: google.maps.places.AutocompletePrediction | unknown | undefined;
  }) => {
    const addressPrediction =
      option as google.maps.places.AutocompletePrediction;
    if (
      option &&
      addressPrediction.matched_substrings &&
      addressPrediction.matched_substrings.length > 0
    ) {
      return (
        <h1 className="flex text-xs">
          <BoldSubstring
            text={addressPrediction.description}
            length={addressPrediction.matched_substrings[0].length}
            offset={addressPrediction.matched_substrings[0].offset}
          />
        </h1>
      );
    } else {
      return null;
    }
  };

  function handleClear() {
    setInputValue("");
    setIsEditable(true);
    handleAddressChange(undefined);
  }

  if (!control) {
    return (
      <Combobox
        size={size || undefined}
        inputRef={inputRef}
        value={inputValue || value || ""}
        positive={value ? true : positive}
        onBlur={() => {
          if (cleanOnBlur) {
            if (!value) {
              setInputValue("");
              setIsEditable(true);
            }
          }
        }}
        onChange={(b, a) => {
          handleChange(b, a as google.maps.places.AutocompletePrediction);
        }}
        mapOptionToString={(a) =>
          mapOptionToString(a as google.maps.places.AutocompletePrediction)
        }
        options={predictions}
        name="async-options"
        mapOptionToNode={renderOptionNode}
        overrides={{
          Input: {
            props: {
              placeholder: placeholder,
              autoComplete: autoComplete,
            },
          },
        }}
        disabled={disabled}
      />
    );
  }

  return (
    <Controller
      name={name || "streetAddress"}
      control={control}
      rules={{ required: value ? false : required }}
      render={({ fieldState: { error }, field: { ref: callbackRef } }) => {
        callbackRef(inputRef.current);
        return isEditable ? (
          <Combobox
            error={error ? true : false}
            size={size || undefined}
            inputRef={inputRef}
            value={inputValue || value || ""}
            positive={value ? true : positive}
            onBlur={() => {
              if (cleanOnBlur) {
                if (!value) {
                  setInputValue("");
                  setIsEditable(true);
                }
              }
            }}
            onChange={(b, a) => {
              handleChange(b, a as google.maps.places.AutocompletePrediction);
            }}
            mapOptionToString={(a) =>
              mapOptionToString(a as google.maps.places.AutocompletePrediction)
            }
            options={predictions}
            name="async-options"
            mapOptionToNode={renderOptionNode}
            overrides={{
              Input: {
                props: {
                  placeholder: placeholder,
                  error: error ? true : false,
                  autoComplete: autoComplete,
                },
              },
            }}
            disabled={disabled}
          />
        ) : (
          <Input
            size={size || undefined}
            value={inputValue || value || ""}
            readOnly={true}
            clearable={true}
            positive={value ? true : positive}
            overrides={{
              Root: {
                style: {
                  position: "relative",
                },
              },
              Input: {
                style: {
                  backgroundColor: "rgb(249 250 251)",
                  marginRight: "1rem",
                  cursor: "auto",
                },
              },
              ClearIconContainer: {
                style: {
                  border: "1px solid green",
                  display: "none",
                },
              },
              After: () => (
                <XMarkIcon
                  className="w-6 h-6 my-auto cursor-pointer hover:text-primary-500"
                  onClick={handleClear}
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: size === "mini" ? "2px" : "10px",
                  }}
                />
              ),
            }}
          />
        );
      }}
    />
  );
}
